@use "../scss/variable" as *;

.spinner-container {
    background-color: $neutral-transparent-700;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
}