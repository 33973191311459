@use "./variable" as *;
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    background-color: $neutral-200;
    display: flex;
    min-height: 100vh;
    height: 100%;
}

.wrapper {
    width: 100%;
    height: 100%;
}

@media (min-width: 992px) {
    .wrapper {
        padding-left: $sidebar-width;
    }
}
@import '~bootstrap/scss/bootstrap.scss';
@import './utils';