@use "../../scss/variable" as *;

.sidebar {
    position: fixed;
    left: - $sidebar-width;
    z-index: 2;
    height: 100vh;
    width: $sidebar-width;
    background-color: $neutral-800;
    color: $white;
    transition: left ease 250ms;

    &-open {
        left: 0;
    }

    &-header {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1em;
    }

    &-close-btn {
        position: absolute;
        top: $navbar-height / 3.75;
        right: 5px;
        z-index: 1;
    }
}

.nav-link {
    padding: 0;
    text-decoration: none;
    color: $white;
    display: flex;
    align-items: center;
    gap: 0.5em;

    &:hover {
        color: $white;
    }

    &:focus {
        color: $white;
    }
}

@media (min-width: 992px) {
    .sidebar {
        width: $sidebar-width;
        left: 0;

        &-close-btn {
            display: none;
        }
    }
}