/*=======================================================
                        LAYOUTS
=======================================================*/

$navbar-height: 4em;
$sidebar-width: 15em;

/*=======================================================
            FONTS
=======================================================*/

$fs-small-100: .5em;
$fs-small-200: .875em;
$fs-small-300: 1rem;
$fs-small-400: 1.125rem;
$fs-small-500: 1.563rem;
$fs-small-600: 1.953rem;
$fs-small-700: 2.441rem;
$fs-small-800: 3.052rem;

$fs-large-300: 1rem;
$fs-large-400: 1.425rem;
$fs-large-500: 2rem;
$fs-large-600: 2.827rem;
$fs-large-700: 4rem;
$fs-large-800: 5.653rem;
/*=======================================================
                        COLORS
=======================================================*/

$orange-900: #7c2d12;
$orange-800: #9a3412;
$orange-700: #c2410c;
$orange-600: #ea580c;
$orange-500: #F97316;
$orange-400: #fb923c;
$orange-300: #fdba74;
$teal-900: #134e4a;
$teal-800: #115e59;
$teal-700: #0f766e;
$teal-600: #0d9488;
$teal-500: #14b8a6;
$teal-400: #2dd4bf;
$teal-300: #5eead4;
$neutral-900: #171717;
$neutral-800: #262626;
$neutral-700: #404040;
$neutral-600: #525252;
$neutral-500: #737373;
$neutral-400: #a3a3a3;
$neutral-300: #d4d4d4;
$neutral-200: #e5e5e5;
$neutral-100: #f5f5f5;
$neutral-50: #fafafa;
$neutral-transparent-700: hsla(0, 0%, 25%, 0.25);
$white: #ffffff;
$black: #000000;
$red: #dc3545;

/*=======================================================
            OVERWRITE - BOOTSTRAP
=======================================================*/

$dark: $neutral-900;
$spinner-width: 5em;
$spinner-border-width: 0.4em;