@use "../../scss/variable" as *;

.navbar {
    width: 100%;
    height: $navbar-height;
    background-color: $neutral-100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em;
}

.user-name {
    display: none;
}

@media (min-width: 992px) {
    .user-name {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.25em;
    }

    .sidebar-button {
        display: none;
    }
}