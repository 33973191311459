.divider-white {
    width: 95%;
    height: 0.1em;
    background-color: $neutral-100;
    border-radius: 15px;
    margin: 1em auto;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25em
}

.rotate {
    transition: transform ease 200ms;
}

.rotate-180 {
    transform: rotate(180deg);
}

.blur-screen {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: $neutral-transparent-700;
    z-index: 1;
}

.error-msg {
    display: block;
    height: 1rem;
    color: $red;
    font-size: $fs-small-200;
}

.is-invalid-field {
    border-color: $red;
}
.btn-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5em;
}
.table-container {
    height: 30rem;
    overflow: scroll;
}
.c-pointer {
    cursor: pointer;
}