$time-animation: 50ms;
$complete-scale: 1;
$half-scale: 0.25;

.scale-up-center {
    -webkit-animation: scale-up-center $time-animation cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-center $time-animation cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale($half-scale);
        transform: scale($half-scale);
    }

    100% {
        -webkit-transform: scale($complete-scale);
        transform: scale($complete-scale);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale($half-scale);
        transform: scale($half-scale);
    }

    100% {
        -webkit-transform: scale($complete-scale);
        transform: scale($complete-scale);
    }
}

.scale-down-center {
    -webkit-animation: scale-down-center $time-animation cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-down-center $time-animation cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-down-center {
    0% {
        -webkit-transform: scale($complete-scale);
        transform: scale($complete-scale);
    }

    100% {
        -webkit-transform: scale($half-scale);
        transform: scale($half-scale);
    }
}

@keyframes scale-down-center {
    0% {
        -webkit-transform: scale($complete-scale);
        transform: scale($complete-scale);
    }

    100% {
        -webkit-transform: scale($half-scale);
        transform: scale($half-scale);
    }
}